#cx-main {
  transition: all 0.5s;
  padding: 80px 20px 20px 20px;
  background: #eeedde;
  min-height: calc(100vh - 60px);
  min-height: 100vh;
  margin-left: 250px;
}
.cx-active {
  margin-left: 60px !important;
  transition: all 0.5s;
}
.cx-header-wrapper.cx-active {
  width: calc(100% - 60px) !important;
  margin-left: 60px !important;
}

.btn-slide {
  background: #00bfb6;
  position: relative;
  .btn {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    z-index: 1;
    transition: all 1s ease;
    width: 200px;
    height: 50px;
    line-height: 50px;
    border: 2px solid #fff;
    margin: 30px 0;
    position: relative;
    cursor: pointer;
    transition: all 1s ease;
    overflow: hidden;
  }

  .btn:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    transition: all 1s ease;
  }
  .btn-2:before {
    top: 0;
    left: -210px;
  }

  /* Right Slider */
  .btn-1:before {
    top: 0;
    left: 210px;
  }

  // .btn:hover {
  //   color: #00bfb6 ;
  // }

  // .btn-2:hover::before,
  // .btn-1:hover::before {
  //   left: 0;
  // }

  label {
  }

  .btn1-active::before {
    left: 0;
  }
  .btn2-active::before {
    left: 0;
  }
}

  thead {
      td {
        text-align: center !important;
      }
    
  }

