.dashboard-main {
  .dashboard-wrapper {
    .main-section {
      justify-content: space-between;
    }
    .left-section {
      width: calc(50% - 5px);
      h5 {
        color: #44667f;
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 600;
        border-bottom: 1px solid #898e8e;
        padding: 0 15px 15px 15px;
      }
      background-color: #ffffff;
      border-radius: 10px;
      padding: 15px;
    }
    .right-section {
      .ineerCard {
        .innerText {
          background-color: #747b26;
          .headingText,
          .count {
            color: #ffffff !important;
          }
        }
        .innerIcon {
          background-color: #ffffff !important;
        }
      }
      width: calc(50% - 5px);

      background-color: #ffffff;
      border-radius: 10px;
      padding: 15px;
      h5 {
        color: #44667f;
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 600;
        border-bottom: 1px solid #898e8e;
        padding: 0 15px 15px 15px;
      }
    }

    .mainCards {
      text-decoration: none;

      .ineerCard {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        height: 100px;

        width: 100%;

        display: flex;

        cursor: pointer;

        background-color: #fff;

        position: relative;

        overflow: hidden;

        margin-bottom: 20px;

        border: 0px solid transparent;

        border-radius: 0.75rem;

        .innerIcon {
          background-color: #747b26;

          width: 80px;

          // padding: 10px;

          text-align: center;

          line-height: 80px;

          img {
            // color: #fff;

            text-align: center;

            vertical-align: middle;

            margin-top: 10px;

            height: 50px;

            width: 50px;

            line-height: 80px;
          }
        }
      }

      .innerText {
        width: 70%;

        padding: 12px;

        display: flex;

        flex-direction: column;

        .headingText {
          color: #44667f;

          font-size: 16px;

          font-weight: 550;

          text-decoration: none;
        }

        .count {
          margin-top: 0px;
          font-size: 22px;

          font-weight: 550;

          color: #44667f;

          text-align: end;
        }
      }
    }
  }
}

.heading-btn-main {
  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 20px;
}

.heading-common {
  label {
    font-size: 24px;

    font-weight: 700;

    color: #84859d;
  }
}

.buttons-modal {
  button,
  a {
    text-decoration: none;

    border: none;

    background-color: #44667f;

    color: #fff;

    border-radius: 10px;

    padding: 5px 15px;
  }
}

.admin-main {
  .add-btn {
    text-align: end;

    button {
      border: none;

      color: #fff;

      border-radius: 5px;

      padding: 7px 10px;

      background-color: #84859d;

      &:hover {
        background-color: #696a7d;
      }

      svg {
        height: 20px;

        margin-bottom: 2px;
      }
    }
    .search_option {
      position: relative;
      .search {
        position: absolute;
        top: 0;
        right: 13px;
        padding: 6.3px 10px;
        border-radius: 0 5px 5px 0;
      }
    }
  }
}

.table-wrapper {
  overflow-x: auto;

  background-color: #fff;

  padding: 20px;

  border-radius: 10px;

  table {
    th {
      //   text-align: center;
    }

    td {
      text-align: center;
    }

    min-width: 900px;

    thead {
      tr:first-child th:last-child {
        border-top-right-radius: 10px;
      }

      tr:first-child th:last-child {
        border-bottom-right-radius: 10px;
      }

      tr:first-child th:first-child {
        border-top-left-radius: 10px;
      }

      tr:first-child th:first-child {
        border-bottom-left-radius: 10px;
      }

      background-color: #44667f;

      th {
        color: #fff;
      }
    }

    .verify {
      a {
        display: inline-block;
        width: 130px;
        padding: 3px;
        color: #fff;
        text-decoration: none;
        border-radius: 5px;

        background-color: #84859d;

        &:hover {
          background-color: #696a7d;
        }
      }
    }

    .action {
      .icon {
        img {
          height: 18px;

          width: 18px;

          vertical-align: middle;
        }

        margin: 0 3px;
        padding: 2px 5px;

        border-radius: 5px;

        background-color: #84859d;
      }
    }
  }
}

table.dataTable thead th {
  text-align: center;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 20px;
}

.dataTables_wrapper .dataTables_length select {
  margin-left: 10px;

  width: 150px;

  outline: none;
}

.dataTables_wrapper .dataTables_filter input {
  outline-offset: 0px;

  outline: none;
}

.dataTables_empty {
  color: red;
}

.dataTables_wrapper .dataTables_paginate {
  margin-top: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background-color: #44667f;

  color: #fff !important;

  &:hover {
    background-color: #44667f;

    color: #fff !important;
  }
}

.dataTables_wrapper .dataTables_info {
  margin-top: 20px;
}

.heading-btn-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.heading-common {
  label {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    color: #000;
    span {
      font-size: 18px;
      font-weight: 500;
    }
  }
  p {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin-bottom: 0;
  }
}

.buttons-modal {
  button,
  a {
    text-decoration: none;
    border: none;
    background-color: #747b26;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
  }
}

.admin-main {
  .add-btn {
    text-align: end;
    margin-bottom: 10px;
    button {
      border: none;
      color: #fff;
      border-radius: 5px;
      padding: 7px 10px;
      background-color: #747b26;

      &:hover {
        background-color: #afb57d;
      }

      svg {
        height: 20px;
        margin-bottom: 2px;
      }
    }
  }
}

.table-wrapper {
  overflow-x: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  table {
    th {
      //   text-align: center;
    }

    td {
      text-align: center;
      a {
        color: #212529;
        text-decoration: none;
      }
    }

    min-width: 800px;

    thead {
      tr:first-child th:last-child {
        border-top-right-radius: 10px;
      }

      tr:first-child th:last-child {
        border-bottom-right-radius: 10px;
      }

      tr:first-child th:first-child {
        border-top-left-radius: 10px;
      }

      tr:first-child th:first-child {
        border-bottom-left-radius: 10px;
      }

      background-color: #afb57d;

      th {
        color: #fff;
        text-align: center;
      }
    }

    .action {
      .reciter_wrapepr {
        display: flex;
        justify-content: space-between;
        .form-control {
          height: 30px;
          width: 60%;
        }
      }
      .view {
        background-color: #6c757d;
        &:hover {
          background-color: #94a2af;
          transition: all 0.2s;
        }
      }
      .edit {
        color: white;
        background-color: #198754;
        &:hover {
          background-color: rgb(94, 184, 94);
          transition: all 0.2s;
        }
      }
      .delete {
        background-color: #dc3545;
        &:hover {
          background-color: rgb(192, 64, 64);
          transition: all 0.2s;
        }
      }
      .icon {
        img {
          height: 18px;
          width: 18px;
          vertical-align: middle;
        }

        margin: 0 3px;
        padding: 2px 5px;
        border-radius: 5px;
      }
    }
  }
}

table.dataTable thead th {
  text-align: center;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 20px;
}

.dataTables_wrapper .dataTables_length select {
  margin-left: 10px;
  width: 150px;
  outline: none;
}

.dataTables_wrapper .dataTables_filter input {
  outline-offset: 0px;
  outline: none;
}

.dataTables_empty {
  color: red;
}

.dataTables_wrapper .dataTables_paginate {
  margin-top: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background-color: #afb57d;
  color: #fff !important;

  &:hover {
    background-color: #afb57d;
    color: #fff !important;
  }
}

.dataTables_wrapper .dataTables_info {
  margin-top: 20px;
}

.modal-content {
  .plus-btn,
  .minus-btn {
    button {
      font-size: 15px;
      font-weight: 700;
    }
  }
  .add-newSabaq-btn {
    text-decoration: none;
    width: calc(100% - 1.8rem);
    margin: 0 0.9rem 15px 0.9rem;
  }
  .sub-group-main {
    margin-bottom: 15px !important;
    margin: 0 0.9rem;
    padding: 10px;
    width: calc(100% - 1.8rem);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .p-details-img {
    display: flex;
    flex-wrap: wrap;
    img {
      height: 90px;
      width: 90px;
      border-radius: 10px;
      margin-right: 15px;
    }
  }
  .product-img-main {
    flex-wrap: wrap;
    margin: 40px 0 30px 0;
    .products {
      margin-right: 20px;
      margin-bottom: 20px;
      width: 90px;
      height: 90px;
      padding: 10px 0;
      text-align: center;
      position: relative;
      border: 1px dashed gray;
      border-radius: 10px;
      .cross {
        position: absolute;
        right: -8px;
        top: -8px;
        height: 15px;
        width: 15px;
      }
      span {
        font-size: 11px;
        color: gray;
        display: block;
      }
      svg {
        display: inline-block;
        height: 50px;
        width: 50px;
      }
      .img-product {
        height: 70px;
        width: 70px;
      }
    }
  }
  .details-input {
    width: calc(100% - 70px);
    .remove {
      img {
        height: 20px;
        width: 20px;
      }
      cursor: pointer;
      position: absolute;
      right: -55px;
      bottom: 7px;
    }
  }
  .modal-title {
    font-size: 18px;
    font-weight: 600;
  }
  .check-input {
    cursor: pointer;
    margin-left: 30px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    .form-check-input {
      height: 30px !important;
      width: 30px;
      margin-right: 10px;
    }
  }
  .user-detail {
    margin-bottom: 15px;
    label {
      color: #84859d;
      font-size: 14px;
    }
    span,
    p {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      // margin-left: 30px;
    }
  }

  .table-user-details {
    overflow-x: auto;
    table {
      border: 1px solid #44667f;
      border-collapse: collapse;
      min-width: 1000px;
      width: 100%;
      th,
      td {
        padding: 7px 10px;
        border: 1px solid #44667f;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
      }
      thead {
        .item-heading {
          th {
            color: #84859d;
          }
        }
        .table-heading {
          th {
            color: #44667f;
          }
        }
      }
      tbody {
        td {
          color: #222222;
        }
      }
    }
  }
}
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none;
}

.order-select-input {
  align-items: flex-end;
  button {
    text-decoration: none;
    border: none;
    background-color: #44667f;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
  }
}

.modal-content {
  .key {
    color: #707070;
    font-size: 16px;
  }
  .value {
    font-size: 18px;

    color: #000000;
    font-weight: 600;
  }
}

.wrapper-push-notification {
  .main-section {
    justify-content: space-between;
    h4 {
      font-size: 20px;
      color: #747b26;
      font-weight: 600;
      margin-bottom: 20px;
    }
    h5 {
      font-size: 16px;
      color: #747b26;
      font-weight: 600;
    }
  }
  .left-section {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: calc(50% - 5px);
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;

    p {
      font-size: 14px;
    }

    .step {
      padding: 10px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      background-color: cream;
    }

    .v-stepper {
      position: relative;
      /*   visibility: visible; */
    }

    /* regular step */
    .step .circle {
      background-color: white;
      border: 10px solid gray;
      border-radius: 100%;
      width: 32px;
      height: 32px;
      display: inline-block;
    }
    .step .line {
      top: 23px;
      left: 14px;
      /*   height: 120px; */
      height: 100%;

      position: absolute;
      border-left: 3px solid #747b26;
    }

    .step.completed .circle {
      visibility: visible;
      background-color: rgb(6, 150, 215);
      border-color: rgb(6, 150, 215);
    }

    .step.completed .line {
      border-left: 3px solid #747b26;
    }

    .step.active .circle {
      visibility: visible;
      border-color: #747b26;
    }

    .step.empty .circle {
      visibility: hidden;
    }

    .step.empty .line {
      /*     visibility: hidden; */
      /*   height: 150%; */
      top: 0;
      height: 150%;
    }

    .step:last-child .line {
      border-left: 3px solid white;
      z-index: -1; /* behind the circle to completely hide */
    }

    .content {
      margin-left: 20px;
      display: inline-block;
    }
  }
  .right-section {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    // width: calc(50% - 5px);
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;

    .form-control {
      border-radius: 0;
    }
    .form-label {
    }
    button {
      border-radius: 7px;
      padding: 6px 25px;
      border: none;
      background-color: #747b26;
      color: #ffffff;
    }
  }
}

.nav-link.active {
  background-color: #747b26 !important;
}

.nav-link {
  color: #747b26;
  font-weight: 600;

  &:hover {
    color: #747b26;
    font-weight: 600;
  }
}

.jobDescriptio-main {
  .jd-main {
    // justify-content: space-evenly;
    // flex-wrap: wrap;
    position: relative;
  }
  .add-btn {
    .form-control {
      display: inline-block !important;
      width: 70% !important;
      margin-right: 10px;
    }
  }
  .jd-cards {
    padding: 15px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 7px;
    // width: calc(33.33333333% - 15px);
    .add-btn {
      button {
        img {
          height: 15px;
          width: 15px;
        }
        padding: 2px 7px;
        margin-top: 0;
      }
    }
    h6 {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      word-break: break-all;
      margin-top: 10px;
    }
    p {
      white-space: break-spaces;
      text-align: justify;
      font-size: 14px;
      color: #707070;
    }
  }
  margin-top: 20px;
  .nav-link.active {
    background-color: transparent !important;
    color: #b33a40;
    font-weight: 700;
    border-bottom: 2px solid #b33a40;
  }
  .nav-link {
    margin-right: 15px;
    color: #000;
    border-radius: 0;
  }
}

.Syllabus_Custom_Tabs {
  #cx-main {
    margin: 0;
    padding: 0;
    min-height: calc(100vh - 150px);
  }
  .heading-common {
    visibility: hidden;
  }
}
